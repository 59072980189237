import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-48 mb-8 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">América Administradora</h2>
        <p className="mt-5">Sua nova administradora de condomínios para Rio Preto e região</p>
        <p className="mt-5">Rua Miguel Landutti, n° 28</p>
        <p className="mt-5">Vila Diniz - São José do Rio Preto/SP</p>
        <p className="mt-5">17 - 3305-6659</p>
      </div>
      {/* <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://codebushi.com">Terms &amp; Conditions</a>
          </li>
          <li>
            <a href="https://codebushi.com">Privacy Policy</a>
          </li>
        </ul>
      </div> */}
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Mídias Sociais</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://www.facebook.com/americaadministradora">Facebook</a>
          </li>
          <li>
            <a href="https://www.instagram.com/americaadministradora/">Instagram</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
