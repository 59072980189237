import React from 'react';

const Logo = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="514.000000pt"
    height="454.000000pt"
    viewBox="0 0 514.000000 454.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,454.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M2600 4430 l-195 -98 -5 -281 -5 -281 -78 55 c-42 30 -80 55 -82 55
-3 0 -5 -294 -5 -652 l0 -653 -123 -76 -122 -76 -3 -344 -2 -344 -156 -23
c-367 -53 -758 -164 -1074 -305 -139 -63 -284 -137 -277 -143 2 -2 41 10 88
27 583 214 1284 329 2009 329 726 0 1424 -114 2009 -329 47 -17 86 -29 89 -27
6 6 -114 67 -272 139 -309 140 -714 256 -1083 310 l-153 22 -2 707 -3 707
-105 -64 -105 -65 -3 183 -2 182 -70 48 -70 48 -2 523 -3 524 -195 -98z m100
-476 c0 -218 -3 -394 -8 -392 -4 2 -47 30 -94 63 l-87 60 -1 290 0 291 83 41
c45 23 88 42 95 42 9 1 12 -85 12 -395z m-105 -457 l240 -172 3 -185 2 -185
-241 -150 c-133 -83 -245 -151 -250 -153 -5 -2 -9 211 -9 507 0 281 3 511 8
510 4 0 115 -77 247 -172z m455 -1147 l0 -601 -147 8 c-189 10 -477 10 -665 0
l-148 -8 0 308 1 308 472 292 c260 161 475 293 480 293 4 0 7 -270 7 -600z"
      />
      <path
        d="M2103 1320 l-31 -70 33 0 c28 0 40 8 76 53 24 28 49 60 57 70 14 16
11 17 -45 17 l-59 0 -31 -70z"
      />
      <path
        d="M3771 1210 c-152 -32 -248 -152 -258 -324 -9 -141 33 -265 116 -337
60 -53 130 -73 235 -67 93 5 141 26 198 85 34 35 77 113 78 139 0 12 -78 36
-91 29 -4 -3 -10 -17 -14 -32 -20 -84 -101 -143 -196 -143 -145 1 -229 107
-229 289 0 91 10 132 49 192 38 61 102 93 186 93 37 0 72 -7 93 -18 34 -18 92
-89 92 -113 0 -12 9 -13 43 -8 23 4 45 10 50 14 12 11 -42 108 -76 137 -68 57
-181 83 -276 64z"
      />
      <path
        d="M440 878 c-68 -178 -129 -338 -137 -356 l-14 -33 52 3 51 3 24 65
c13 36 31 84 40 108 l16 42 147 0 148 0 43 -110 42 -110 53 0 54 0 -101 248
c-56 136 -120 294 -144 352 l-42 105 -54 3 -54 3 -124 -323z m243 57 l55 -150
-115 -3 c-64 -1 -117 -1 -120 1 -2 2 19 64 46 138 27 74 52 150 56 169 7 30 9
32 16 15 4 -11 32 -87 62 -170z"
      />
      <path
        d="M1030 845 l0 -355 45 0 45 0 0 302 c0 173 4 298 9 293 5 -6 52 -142
106 -302 l97 -293 42 0 43 0 63 183 c35 100 81 234 104 297 l41 115 3 -297 2
-298 45 0 45 0 0 355 0 356 -67 -3 -67 -3 -100 -292 c-56 -161 -103 -293 -106
-293 -3 0 -49 133 -103 295 l-99 295 -74 0 -74 0 0 -355z"
      />
      <path
        d="M1870 845 l0 -355 270 0 270 0 0 40 0 40 -220 0 -220 0 0 125 0 125
195 0 195 0 0 40 0 40 -195 0 -195 0 0 110 0 110 210 0 210 0 0 40 0 40 -260
0 -260 0 0 -355z"
      />
      <path
        d="M2540 845 l0 -355 45 0 45 0 0 155 0 155 97 0 97 0 49 -52 c26 -29
76 -98 111 -153 l62 -100 62 -3 63 -3 -77 118 c-88 136 -96 147 -146 183 l-38
27 36 6 c126 21 197 148 149 264 -21 50 -42 72 -90 94 -35 16 -68 19 -252 19
l-213 0 0 -355z m370 276 c81 -16 122 -92 88 -165 -23 -53 -50 -61 -215 -64
l-153 -4 0 114 c0 63 3 118 7 121 9 10 220 8 273 -2z"
      />
      <path d="M3270 845 l0 -355 50 0 50 0 0 355 0 355 -50 0 -50 0 0 -355z" />
      <path
        d="M4335 893 c-65 -170 -126 -330 -136 -356 l-19 -48 51 3 52 3 37 105
37 105 150 3 150 3 42 -111 42 -110 55 0 c30 0 53 3 51 8 -3 4 -55 131 -117
282 -62 151 -125 308 -142 348 l-30 72 -53 0 -52 0 -118 -307z m264 -30 l30
-83 -119 0 c-66 0 -120 3 -120 8 0 4 20 59 43 122 24 63 50 137 57 164 l13 48
32 -88 c18 -49 46 -126 64 -171z"
      />
      <path
        d="M2080 315 c-15 -18 -5 -34 12 -17 8 8 17 8 30 1 21 -11 52 6 42 23
-4 5 -10 5 -18 -1 -7 -6 -17 -6 -26 -1 -20 13 -26 12 -40 -5z"
      />
      <path d="M4383 315 c-9 -25 2 -27 22 -5 17 18 17 20 1 20 -9 0 -19 -7 -23 -15z" />
      <path
        d="M50 198 c-18 -46 -36 -93 -41 -105 -7 -18 -5 -23 7 -23 8 0 20 14 25
30 10 28 15 30 59 30 46 0 49 -2 55 -30 4 -20 13 -30 25 -30 17 0 15 11 -24
103 -25 61 -49 103 -58 105 -12 2 -25 -19 -48 -80z m79 -30 c1 -5 -14 -8 -33
-8 l-34 0 17 45 16 46 17 -38 c9 -21 17 -41 17 -45z"
      />
      <path
        d="M220 175 l0 -105 50 0 c89 0 120 30 120 116 0 68 -31 94 -110 94
l-60 0 0 -105z m125 65 c17 -14 20 -92 5 -121 -8 -14 -21 -19 -55 -19 l-45 0
0 81 0 82 40 -6 c23 -3 47 -11 55 -17z"
      />
      <path
        d="M430 176 c0 -88 3 -106 15 -106 12 0 15 16 16 83 l1 82 27 -80 c17
-49 33 -80 42 -80 8 0 24 30 41 75 l26 75 4 -74 c2 -48 7 -76 16 -79 9 -3 12
22 12 102 0 96 -2 106 -19 106 -14 0 -25 -19 -47 -84 -15 -47 -31 -83 -34 -80
-3 3 -17 40 -31 82 -20 61 -30 78 -47 80 -21 3 -22 0 -22 -102z"
      />
      <path
        d="M680 175 c0 -87 3 -105 15 -105 12 0 15 18 15 105 0 87 -3 105 -15
105 -12 0 -15 -18 -15 -105z"
      />
      <path
        d="M760 175 c0 -124 18 -143 22 -23 l3 82 54 -82 c29 -45 60 -82 67 -82
11 0 14 23 14 105 0 124 -18 143 -22 23 l-3 -82 -54 82 c-29 45 -60 82 -67 82
-11 0 -14 -23 -14 -105z"
      />
      <path
        d="M970 175 c0 -87 3 -105 15 -105 12 0 15 18 15 105 0 87 -3 105 -15
105 -12 0 -15 -18 -15 -105z"
      />
      <path
        d="M1067 262 c-21 -23 -22 -55 -3 -71 7 -6 37 -18 65 -27 55 -16 69 -44
32 -64 -30 -16 -50 -12 -77 15 -14 14 -29 25 -34 25 -17 0 -11 -20 15 -45 59
-60 179 -4 136 63 -5 8 -33 22 -62 31 -35 12 -55 24 -57 36 -8 37 57 48 85 15
20 -23 33 -26 33 -7 0 44 -101 65 -133 29z"
      />
      <path
        d="M1230 270 c0 -5 16 -10 35 -10 l35 0 0 -95 c0 -78 3 -95 15 -95 12 0
15 17 15 95 l0 95 35 0 c19 0 35 5 35 10 0 6 -35 10 -85 10 -50 0 -85 -4 -85
-10z"
      />
      <path
        d="M1430 175 c0 -63 4 -105 10 -105 6 0 10 20 10 45 0 45 0 45 35 45 32
0 39 -5 63 -45 16 -26 34 -44 46 -45 18 0 18 1 1 28 -10 15 -26 36 -36 48 -19
20 -19 21 6 32 20 9 25 19 25 46 0 45 -20 56 -99 56 l-61 0 0 -105z m124 69
c12 -12 13 -20 6 -35 -8 -15 -22 -19 -60 -19 -48 0 -50 1 -50 28 0 16 3 32 7
35 13 13 82 7 97 -9z"
      />
      <path
        d="M1658 181 c-43 -111 -43 -111 -24 -111 8 0 17 13 21 30 6 28 9 30 55
30 44 0 49 -2 59 -30 5 -17 17 -30 27 -30 9 0 14 6 11 13 -54 138 -83 197 -96
197 -11 0 -28 -31 -53 -99z m71 28 c16 -46 14 -49 -20 -49 -24 0 -30 3 -25 16
3 9 9 27 12 40 8 33 20 30 33 -7z"
      />
      <path
        d="M1855 255 c-46 -46 -26 -167 31 -181 14 -4 23 -9 20 -12 -3 -3 -1
-11 5 -19 9 -10 8 -13 -5 -13 -9 0 -16 -4 -16 -10 0 -14 47 -13 56 1 3 6 1 16
-5 22 -16 16 -14 27 4 27 18 0 65 43 65 60 0 17 -18 11 -44 -15 -30 -30 -59
-32 -86 -5 -31 31 -30 105 2 133 27 23 57 22 84 -4 32 -30 50 -20 24 14 -30
37 -99 39 -135 2z"
      />
      <path
        d="M2063 175 c-31 -79 -37 -105 -26 -105 8 0 19 14 24 30 10 28 15 30
58 30 43 0 48 -3 58 -30 6 -17 18 -30 27 -30 14 0 13 7 -6 53 -11 28 -30 76
-42 104 -32 79 -46 71 -93 -52z m66 53 c25 -66 24 -68 -8 -68 -35 0 -35 -1
-19 51 13 45 16 47 27 17z"
      />
      <path
        d="M2273 265 c-32 -23 -48 -79 -34 -125 33 -109 191 -85 191 30 0 44
-13 77 -39 97 -22 17 -92 16 -118 -2z m100 -17 c45 -34 35 -134 -15 -152 -57
-20 -106 33 -93 101 10 53 68 80 108 51z"
      />
      <path
        d="M2550 174 l0 -106 65 4 c42 2 71 10 81 20 22 21 32 75 23 118 -12 52
-40 70 -110 70 l-59 0 0 -106z m129 61 c19 -19 20 -97 1 -120 -8 -9 -29 -15
-56 -15 l-44 0 0 81 0 82 41 -6 c23 -3 49 -13 58 -22z"
      />
      <path
        d="M2760 175 l0 -105 80 0 c64 0 80 3 80 15 0 12 -14 15 -65 15 l-65 0
0 35 0 35 60 0 c33 0 60 4 60 10 0 6 -27 10 -60 10 l-60 0 0 35 0 35 65 0 c37
0 65 4 65 10 0 6 -33 10 -80 10 l-80 0 0 -105z"
      />
      <path
        d="M3073 265 c-33 -23 -48 -79 -34 -126 14 -45 44 -69 87 -69 46 0 63 9
80 41 19 37 1 39 -32 4 -13 -14 -32 -25 -42 -25 -59 0 -92 92 -52 143 26 33
74 37 92 7 15 -23 38 -27 38 -6 0 40 -94 61 -137 31z"
      />
      <path
        d="M3283 265 c-26 -19 -44 -76 -36 -116 21 -109 185 -103 194 6 4 49 -3
74 -26 99 -19 20 -33 26 -67 26 -24 0 -53 -7 -65 -15z m103 -20 c47 -32 32
-138 -21 -151 -58 -14 -103 37 -90 103 10 55 67 80 111 48z"
      />
      <path
        d="M3480 175 c0 -85 3 -105 15 -105 11 0 15 18 17 77 l3 77 50 -77 c72
-110 85 -106 85 28 0 87 -3 105 -15 105 -12 0 -15 -16 -15 -82 l0 -83 -55 83
c-75 112 -85 109 -85 -23z"
      />
      <path
        d="M3690 175 l0 -105 49 0 c71 0 99 14 117 57 20 46 11 97 -21 129 -20
20 -34 24 -85 24 l-60 0 0 -105z m127 59 c21 -21 25 -32 20 -65 -7 -55 -22
-69 -73 -69 l-44 0 0 80 0 80 35 0 c25 0 44 -8 62 -26z"
      />
      <path
        d="M3939 267 c-26 -20 -39 -53 -39 -97 0 -57 42 -100 97 -100 49 0 78
21 93 66 15 46 0 106 -33 129 -26 18 -95 19 -118 2z m106 -32 c50 -49 17 -145
-50 -145 -40 0 -65 33 -65 85 0 79 64 112 115 60z"
      />
      <path
        d="M4130 175 c0 -87 3 -105 15 -105 12 0 15 16 16 83 l0 82 15 -40 c37
-104 47 -125 58 -125 7 0 20 26 30 58 10 31 24 68 32 82 12 23 13 17 14 -57 0
-49 4 -83 10 -83 6 0 10 42 10 105 0 94 -2 105 -18 105 -14 0 -24 -20 -46 -85
-15 -47 -29 -85 -31 -85 -2 0 -16 38 -31 85 -25 74 -31 85 -51 85 -23 0 -23
-2 -23 -105z"
      />
      <path
        d="M4380 175 c0 -87 3 -105 15 -105 12 0 15 18 15 105 0 87 -3 105 -15
105 -12 0 -15 -18 -15 -105z"
      />
      <path
        d="M4460 175 c0 -85 3 -105 15 -105 11 0 15 18 17 77 l3 77 50 -77 c28
-42 56 -77 63 -77 9 0 12 28 12 105 0 124 -18 143 -22 23 l-3 -82 -54 82 c-29
45 -60 82 -67 82 -11 0 -14 -23 -14 -105z"
      />
      <path
        d="M4680 175 c0 -63 4 -105 10 -105 6 0 10 42 10 105 0 63 -4 105 -10
105 -6 0 -10 -42 -10 -105z"
      />
      <path
        d="M4783 265 c-26 -19 -44 -76 -36 -116 21 -109 185 -103 194 6 4 49 -3
74 -26 99 -19 20 -33 26 -67 26 -24 0 -53 -7 -65 -15z m103 -20 c47 -32 32
-138 -21 -151 -58 -14 -103 37 -90 103 10 55 67 80 111 48z"
      />
      <path
        d="M4997 262 c-21 -23 -22 -55 -3 -71 7 -6 37 -18 65 -27 55 -16 69 -44
32 -64 -30 -16 -50 -12 -77 15 -14 14 -29 25 -34 25 -17 0 -11 -20 15 -45 59
-60 179 -4 136 63 -5 8 -33 22 -62 31 -40 13 -55 23 -57 39 -2 14 4 22 18 27
30 9 70 -2 70 -20 0 -8 7 -15 15 -15 21 0 19 23 -3 43 -26 23 -94 22 -115 -1z"
      />
    </g>
  </svg>
);

export default Logo;
