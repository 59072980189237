import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Icon from '../../svg/Icon';
import Button from '../Button';

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-12 mr-3 icon">
          <a href="/" target="_self">
            <Icon />
          </a>
        </div>
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#quem-somos">
          Quem somos?
        </AnchorLink>
        <AnchorLink className="px-4" href="#objetivos">
          Objetivos
        </AnchorLink>
        <AnchorLink className="px-4" href="#controle-inadimplencia">
          Controle de inadimplência
        </AnchorLink>
        <AnchorLink className="px-4" href="#inovacao-tecnologia">
          Inovação e tecnologia
        </AnchorLink>
        <AnchorLink className="px-4" href="#app">
          App
        </AnchorLink>
      </div>
      <div className="hidden md:block">
        <a
          href="https://americaadm.superlogica.net/clients/areadocondomino"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            className="text-sm"
            onClick={() =>
              window.open('https://americaadm.superlogica.net/clients/areadocondomino', '_blank')
            }
          >
            Área do condômino
          </Button>
        </a>
      </div>
    </div>
  </header>
);

export default Header;
