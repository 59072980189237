import React from 'react';

const Icon = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="432.000000pt"
    height="330.000000pt"
    viewBox="0 0 432.000000 330.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,330.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M2183 3192 l-193 -97 0 -277 c0 -153 -2 -278 -5 -278 -2 0 -40 25
-82 55 l-78 55 -3 -655 -2 -654 -125 -77 -125 -77 0 -342 0 -342 -117 -18
c-380 -55 -756 -159 -1078 -297 -139 -60 -330 -157 -322 -164 2 -2 41 10 88
27 967 355 2209 433 3299 208 250 -52 579 -145 783 -223 37 -14 67 -23 67 -21
0 2 -46 28 -102 58 -357 191 -841 342 -1320 412 l-118 18 0 703 c0 387 -3 704
-7 704 -5 0 -51 -27 -103 -60 l-95 -59 -3 177 -3 177 -69 49 -70 49 0 524 c0
412 -3 523 -12 522 -7 0 -100 -44 -205 -97z m107 -472 c0 -214 -2 -390 -6
-390 -3 0 -45 28 -95 62 l-89 62 0 286 0 286 83 41 c45 23 88 42 95 42 9 1 12
-83 12 -389z m-95 -466 l235 -167 -2 -185 -3 -185 -240 -149 c-132 -81 -243
-148 -247 -148 -5 0 -8 228 -8 506 0 449 2 505 15 500 9 -3 121 -81 250 -172z
m445 -1140 l0 -606 -36 7 c-119 22 -769 22 -888 0 l-36 -7 0 309 0 308 478
297 c262 164 478 297 480 298 1 0 2 -273 2 -606z"
      />
    </g>
  </svg>
);

export default Icon;
