import React from 'react';
import Logo from '../svg/Logo';
import Button from '../components/Button';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import SvgCharts from '../svg/SvgCharts';

export default () => (
  <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="lg:w-1/2">
          <div className="text-center lg:text-center logo">
            <Logo />
          </div>
        </div>
        <div className="text-center lg:text-left lg:w-1/2">
          {/* <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">América</h1> */}
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Sua nova administradora de condomínios para Rio Preto e região
          </p>
          <p className="mt-8 md:mt-12">
            <a
              href="https://americaadm.superlogica.net/clients/areadocondomino"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                size="lg"
                // onClick={() =>
                //   window.open('https://americaadm.superlogica.net/clients/areadocondomino', '_blank')
                // }
              >
                Área do condômino
              </Button>
            </a>
          </p>
          {/* <p className="mt-4 text-gray-600">Sed fermentum felis ut cursu</p> */}
        </div>
      </div>
    </section>
    {/* <section id="features" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Main Features</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Service One</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Service Two</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Service Three</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section> */}
    <SplitSection
      id="quem-somos"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Quem somos?</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Somos uma empresa especializada na administração de condomínios, composta por
            profissionais capacitados e com vasta experiência no mercado.
          </p>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Prestamos serviços fazendo uso de tecnologias recentes e atualizadas com frequência,
            buscando sempre o que há de mais novo no mercado, proporcionando mais celeridade na
            prestação de serviços, assim como oferecer informações mais seguras e eficazes aos
            nossos clientes, sejam eles condomínios ou condôminos.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      id="objetivos"
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">Objetivos</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Nosso objetivo é oferecer uma assessoria abrangente que resulte em qualidade e
            transparência na gestão do condomínio, proporcionando aos síndicos orientações técnicas
            e legais para facilitar a tomada de decisões, visando o êxito na aplicação dos recursos
            financeiros do condomínio e efetividade nas ações empreendidas.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      id="controle-inadimplencia"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Controle de inadimplência</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Possuímos um departamento jurídico com profissionais altamente qualificados,
            especializados em cobrança, garantindo o suporte necessário para enfrentar problemas com
            condôminos inadimplentes, proporcionando mais saúde financeira ao condomínio.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      id="inovacao-tecnologia"
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">Inovação e tecnologia</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Buscando inovar no mercado, nossos clientes possuem acesso ao aplicativo Área do
            Condômino, disponível nas app stores oficiais para sistemas Androis (Play Store) e
            Apple/iOS (App Store).
          </p>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Pelo aplicativo, os condôminos têm acesso de forma rápida e segura a diversos serviços,
            dentre eles:
          </p>
          <ul>
            <li>- Visualizar boletos em aberto</li>
            <li>- Emitir 2ª via de boletos com valores atualizados</li>
            <li>- Efetuar reservas das áreas comuns</li>
            <li>- Atualizar seu cadastro</li>
            <li>- Receber comunicados emitidos pela América e pelo condomínio</li>
            <li>
              - Acessar documentos como atas de reuniões, convenção do condomínio ou prestações de
              contas
            </li>
          </ul>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    {/* <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section> */}
    {/* <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section> */}
    <section id="app" className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Baixe o app</h3>
      <p className="mt-8 text-xl font-light">
        Para emitir a 2ª via de boletos, baixe o aplicativo <strong>Área do Condômino</strong>,
        disponível para sistemas Android e iOS, clicando em uma das opções abaixo:
      </p>
      <p className="mt-8">
        <div id="slContainer" />
      </p>
    </section>
  </Layout>
);
